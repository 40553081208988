<template>
    <div :class="`mb-3 code hljs-${data.theme !== null ? data.theme : 'dark'}-theme language-${data.language}`">
        <pre class="hljs" v-html="val" />
        <!--        <pre>-->
        <!--            <code class="bash" v-html="val" />-->
        <!--        </pre>-->
    </div>
</template>

<script>
import hljs from 'highlight.js/lib/core';
import langBash from 'highlight.js/lib/languages/bash';
import langCss from 'highlight.js/lib/languages/css';
import langJavascript from 'highlight.js/lib/languages/javascript';
import langJson from 'highlight.js/lib/languages/json';
import langMarkdown from 'highlight.js/lib/languages/markdown';
import langPhp from 'highlight.js/lib/languages/php';
import langPlainText from 'highlight.js/lib/languages/plaintext';
import langPython from 'highlight.js/lib/languages/python';
import langScss from 'highlight.js/lib/languages/scss';
import langShell from 'highlight.js/lib/languages/shell';
import langSql from 'highlight.js/lib/languages/sql';
import langTwig from 'highlight.js/lib/languages/twig';
import langTypescript from 'highlight.js/lib/languages/typescript';
import langXml from 'highlight.js/lib/languages/xml';
import { defineComponent, ref, watch, onMounted } from 'vue';
import { useStoreSystem } from '@/stores/system';

export default defineComponent({
    name: 'EditorBlockCode',
    components: {
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        }
    },
    setup(props) {
        const appSystem = useStoreSystem();
        // console.log(appSystem.theme);
        hljs.configure({
            classPrefix: 'hljs-'
        });
        hljs.registerLanguage('bash', langBash);
        hljs.registerLanguage('plain', langPlainText);
        hljs.registerLanguage('text', langPlainText);
        hljs.registerLanguage('javascript', langJavascript);
        hljs.registerLanguage('typescript', langTypescript);
        hljs.registerLanguage('js', langJavascript);
        hljs.registerLanguage('json', langJson);
        hljs.registerLanguage('markdown', langMarkdown);
        hljs.registerLanguage('php', langPhp);
        hljs.registerLanguage('xml', langXml);
        hljs.registerLanguage('html', langXml);
        hljs.registerLanguage('smarty', langXml);
        hljs.registerLanguage('css', langCss);
        hljs.registerLanguage('scss', langScss);
        hljs.registerLanguage('sass', langScss);
        hljs.registerLanguage('python', langPython);
        hljs.registerLanguage('twig', langTwig);
        hljs.registerLanguage('sql', langSql);
        hljs.registerLanguage('shell', langShell);
        hljs.registerLanguage('vue', langJavascript);
        hljs.registerLanguage('react', langJavascript);

        const val = hljs.highlight(props.data.code, {
            language: props.data.language,
        }).value;
        props.data.theme = appSystem.theme;

        watch(() => appSystem.theme, () => {
            props.data.theme = appSystem.theme;
        });

        if (process.client) {
            // document.querySelectorAll('.language-php').forEach((item) => {
            //     let val = item.innerHTML;
            //     val = val.replaceAll('&lt;', '<');
            //     val = val.replaceAll('&gt;', '>');
            //     console.log(val);
            //     const result = hljs.highlight(val, {
            //         language: 'php',
            //     });
            //     item.outerHTML = '<div class="hljs-light-theme"><pre class="hljs">' + result.value + '</pre></div>';
            //     // console.log(val, result.value);
            // });
            // document.querySelectorAll('.language-mysql').forEach((item) => {
            //     let val = item.innerHTML;
            //     val = val.replaceAll('&lt;', '<');
            //     val = val.replaceAll('&gt;', '>');
            //     const result = hljs.highlight(val, {
            //         language: 'sql',
            //     });
            //     item.outerHTML = '<div class="hljs-light-theme"><pre class="hljs">' + result.value + '</pre></div>';
            //     // console.log(val, result.value);
            // })
        }

        return {
            appSystem,
            val
        };
    },
});
</script>
