<template>
    <blockquote :class="`pr--quote pr--quote--${data.type}  pr--quote--${data.variant}`">
        <p v-html="data.text" />
        <cite v-html="data.caption" />
    </blockquote>
<!--    -->
<!--    <p v-html="data.text" :class="[`text-${data.alignment}`]"/>-->
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EditorBlockQuote',
    components: {
    },
    props: {
        data: {
            type: [Object],
            default: () => {}
        }
    }
});
</script>
